import '../css/Amenities.css';
import 'bootstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faBaby, faDigitalTachograph, faDumbbell, faHotTub, faParking, faSatelliteDish, faScroll, faSmokingBan, faSwimmingPool, faTemperatureHigh, faTemperatureLow, faTshirt, faTv, faWater, faWifi, faChair, faBed, faColumns, faFireExtinguisher } from '@fortawesome/free-solid-svg-icons';

function Amenities() {
  return (
    <div className="container-fluid" id="amenities">
      <h3 id="amenitiesHeading">Amenities</h3>
      <div class="amenitiesContainer d-flex flex-wrap flex-row">
        <div class="amenity">
          <FontAwesomeIcon icon={faBaby} />
          <div className='amenity-description'>Children Welcome</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faChair} />
          <div className='amenity-description'>Highchair</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faBed} />
          <div className='amenity-description'>Crib</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faWater} />
          <div className='amenity-description'>Waterpark</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faSmokingBan} />
          <div className='amenity-description'>No Smoking</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faParking} />
          <div className='amenity-description'>Parking</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faTshirt} />
          <div className='amenity-description'>Washer Dryer</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faTemperatureLow} />
          <div className='amenity-description'>A/C</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faTemperatureHigh} />
          <div className='amenity-description'>Heater</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faWifi} />
          <div className='amenity-description'>Internet</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faTv} />
          <div className='amenity-description'>TV</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faSatelliteDish} />
          <div className='amenity-description'>Cable</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faHotTub} />
          <div className='amenity-description'>Hot Tub</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faSwimmingPool} />
          <div className='amenity-description'>Pool</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faScroll} />
          <div className='amenity-description'>Towels</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faScroll} />
          <div className='amenity-description'>Pool Towels</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faDumbbell} />
          <div className='amenity-description'>Gym</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faFireExtinguisher} />
          <div className='amenity-description'>Extinguisher</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faDigitalTachograph} />
          <div className='amenity-description'>Microwave</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faArchive} />
          <div className='amenity-description'>Dishwasher</div>
        </div>
        <div class="amenity">
          <FontAwesomeIcon icon={faColumns} />
          <div className='amenity-description'>Refrigerator</div>
        </div>
      </div>
      <div>and several other amenities...</div>
    </div>
  );
}

export default Amenities;
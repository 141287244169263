import '../css/ImageCard.css';
import 'bootstrap';
import React from 'react';

class ImageCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={this.props.imageElementClassName}>
        <img className='rounded float img-fluid img-thumbnail' src={this.props.image} alt=""></img>
      </div>
    );
  }
}
export default ImageCard;
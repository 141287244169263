import 'bootstrap';
import { Toast } from 'bootstrap';
import "flatpickr/dist/themes/airbnb.css";
import '../css/Availability.css';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import React from 'react';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getAll } from 'firebase/remote-config';
import axios from 'axios';
import moment from 'moment';
import { DateRangePicker } from "react-dates";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//TODO: Add checks when there is no network coverage
//TODO: Disable DateRangePicker on load until we get calendar availability
//TODO: Show an inquiry form if calendar loading fails
//TODO: Add Refund and Return Policy Page

class Availability extends React.Component {
  state = {
    formCreateCheckoutSessionPath: "",
    formGetCalendarAvailabilityPath: "",
    formGetPricingAvailabilityPath: "",
    pricingData: {},
    finalBookingPrice: 0,
    finalBookingCurrency: "",
    displayBookingPrice: "none",
    startDate: null,
    endDate: null,
    focusedInput: null,
    blockedDays: [],
    minimumNights: 3,
    startDatePlaceholderText: "Check-in",
    endDatePlaceholderText: "Check-out",
    reserveNowDisabled: true,
    showPleaseWaitSpinner: "none"
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const firebaseConfigProd = {
      apiKey: "AIzaSyAQYBGSlTWcUtx6VCigEjddQ_Ox2mBeYTA",
      authDomain: "vacation-rental-project.firebaseapp.com",
      projectId: "vacation-rental-project",
      storageBucket: "vacation-rental-project.appspot.com",
      messagingSenderId: "684006336436",
      appId: "1:684006336436:web:28b8d48b29853a9c40b4e1"
    };

    const firebaseConfigDev = {
      apiKey: "AIzaSyDBqvGR0NrTLfhggfqgO39XPyToTXL1Bvg",
      authDomain: "vacation-rental-project-dev.firebaseapp.com",
      projectId: "vacation-rental-project-dev",
      storageBucket: "vacation-rental-project-dev.appspot.com",
      messagingSenderId: "908405517618",
      appId: "1:908405517618:web:94eecba489e234232bbb2b",
      measurementId: "G-W0XJLPHYB1"
    };

    // TODO: Find a better way to switch between DEV and PROD configs
    const firebaseConfig = (window.location.href.search("localhost") > 0 || window.location.href.search("vacation-rental-project-dev.web.app") > 0) ? firebaseConfigDev : firebaseConfigProd;
    const firebaseApp = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 300;
    this.remoteConfigValues = {};

    // Fetch and Activate Firebase Remote Config for values
    // TODO: Check if this is required here if it is already being called in RemoteAppConfig.js at the application level?
    fetchAndActivate(remoteConfig)
      .then(() => {
        this.remoteConfigValues = getAll(remoteConfig);
        try {
          this.remoteConfigValues = JSON.parse(this.remoteConfigValues.vacationRentalWebAppRemoteConfig._value);
          this.setState({ formCreateCheckoutSessionPath: this.remoteConfigValues["create-checkout-session-path"] });
          this.setState({ formGetCalendarAvailabilityPath: this.remoteConfigValues["get-calendar-availability-path"] });
          this.setState({ formGetPricingAvailabilityPath: this.remoteConfigValues["get-pricing-availability-path"] });
          console.log(this.remoteConfigValues["create-checkout-session-path"]);
          console.log(this.remoteConfigValues["get-calendar-availability-path"]);
          console.log(this.remoteConfigValues["get-pricing-availability-path"]);
        }
        catch {
          console.log("Incorrectly formatted remote config object");
        }
        console.log(this.remoteConfigValues);

        Promise.all([this.getCalendarAvailability(this.state.formGetCalendarAvailabilityPath)])
          .then(function () {
            console.log("Completed execution of remote calls");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getDaysBetweenDates(startDate, endDate) {
    var now = startDate.clone(), dates = [];
    while (now.isSameOrBefore(endDate)) {
      dates.push(now.toArray().slice(0, 3));
      now.add(1, 'days');
    }
    return dates;
  };

  getPricingInformation(startDate, endDate) {
    if (startDate && endDate) {
      axios.post(this.state.formGetPricingAvailabilityPath, {
        fromDate: startDate,
        toDate: endDate
      })
        .then((response) => {
          console.log(response);
          this.setState({ finalBookingPrice: response.data.finalBookingPrice })
          this.setState({ finalBookingCurrency: " " + response.data.currency })
          this.setState({ reserveNowDisabled: false });
          this.setState({ displayBookingPrice: "" });
          this.setState({ showPleaseWaitSpinner: "none" });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  handleDateChange(startDate, endDate) {
    this.setState({ startDate, endDate });

    if (startDate && endDate) {
      var datesBetweenReservationDates = this.getDaysBetweenDates(startDate, endDate);
      console.log(datesBetweenReservationDates);

      var overlappingDates = [];
      datesBetweenReservationDates.forEach(d1 =>
        this.state.blockedDays.some(d2 => {
          if (moment(d1).isSame(moment(d2), "day")) {
            overlappingDates.push(moment(d1));
          }
        })
      );
      console.log(overlappingDates);

      // Get read-only pricing information from the server
      // Display the summary of all charges to the user
      if (overlappingDates.length === 0) {
        //No overlapping dates selected in the reservation
        //TODO: P1 - Add async functionality here
        this.setState({ showPleaseWaitSpinner: "block" });
        this.getPricingInformation(startDate, endDate);
      }
      else {
        this.setState({ endDate: null }); //Setting the endDate to null to ensure that the customer selects a valid date
        var toastErrorElement = document.getElementById("toastReservationDateError");
        var toast = new Toast(toastErrorElement);
        toast.show();
      }
    }
  }

  getCalendarAvailability(path) {
    var disabledDates = [];
    fetch(path)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        data.items.forEach(item => {
          disabledDates.push({ from: item.start.date, to: item.end.date })
        });

        try {
          //CustomDatePicker disabled dates
          var disabledDatesCustomDatePicker = [];
          disabledDates.forEach(item => {
            disabledDatesCustomDatePicker.push.apply(disabledDatesCustomDatePicker, this.getDaysBetweenDates(moment(item.from), moment(item.to)));
          });

          console.log(disabledDatesCustomDatePicker);
          try {
            this.setState({ blockedDays: disabledDatesCustomDatePicker });
          }
          catch (e) {
            console.log(e);
          }
        }
        catch (e) {
          console.log(e);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <section className='checkout border mt-4'>
        <div className='mt-0 me-2 ms-2 sticky-top'>
          <div class="position-fixed bottom-0 end-0 p-3">
            <div id="toastReservationDateError" class="toast alert alert-danger" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="toast-header">
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
              <div class="toast-body">
                Unfortunately the date range you selected is not available. Please select a different date to continue with your booking.
              </div>
            </div>
          </div>
          <form action={this.state.formCreateCheckoutSessionPath} method="POST" className='form-group form-container'>
            <FontAwesomeIcon icon={faSpinner} className='fa-spin' style={{ display: this.state.showPleaseWaitSpinner }} />
            <div className='pt-2 pb-2 price-per-night' style={{ display: this.state.displayBookingPrice }}><b>Total</b> (includes fees, not taxes) <b>{this.state.finalBookingCurrency + " " + this.state.finalBookingPrice.toLocaleString()}</b></div>
            <DateRangePicker
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              startDateId="fromDate" // PropTypes.string.isRequired,
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="toDate" // PropTypes.string.isRequired,
              startDatePlaceholderText={this.state.startDatePlaceholderText}
              endDatePlaceholderText={this.state.endDatePlaceholderText}
              onDatesChange={({ startDate, endDate }) =>
                this.handleDateChange(startDate, endDate)
              } // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              isOutsideRange={momentDateObj =>
                momentDateObj.isBefore(moment().subtract(1, "days").toArray().slice(0, 3)) || momentDateObj.isAfter(moment().add(2, "year").toArray().slice(0, 3))
              }
              minimumNights={this.state.minimumNights}
              isDayBlocked={d1 =>
                this.state.blockedDays.some(d2 => d1.isSame(d2, "day"))
              }
              numberOfMonths={1}
            ></DateRangePicker>
            <input type="number" name="guests" id="guests" className='form-control' placeholder='Enter total number of guests' min="1" max="15" required></input>
            <button className='form-control btn btn-primary' role="link" id="submit" type="submit" disabled={this.state.reserveNowDisabled}>Reserve Now</button>
            <input type='hidden' name='finalBookingPrice' id='finalBookingPrice' value={this.state.finalBookingPrice} readOnly></input>
            <input type='hidden' name='from' id='from' value={this.state.startDate} readOnly></input>
            <input type='hidden' name='to' id='to' value={this.state.endDate} readOnly></input>
          </form>
        </div>
      </section >
    );
  }
}

export default Availability;
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import Nav from './Nav'
import { Link } from 'react-router-dom';

class Canceled extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 100 };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid p-0">
        <Nav></Nav>
        <div className='p-2'>You did not complete your booking for the selected dates. Please try again <Link to="/">here</Link>.</div>
      </div>
    );
  }
};

export default Canceled;

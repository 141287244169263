import '../css/PhotoGrid.css';
import 'bootstrap';
import React from 'react';
import ImageCard from './ImageCard';
import img_Elsa_Anna_1 from '../media/home/Elsa_Anna_1.png';
import img_Star_Wars_0 from "../media/home/Star_Wars_0.png";
import img_Home_Front from '../media/home/Home_Front.jpg';
import img_Dining from '../media/home/Dining.png';
import img_Game_Room from '../media/home/Game_Room_2.jpg';
import img_Lounge from '../media/home/Lounge.jpg';
import img_Pool_1 from '../media/home/Pool_1.png';
import img_Resort from '../media/resort/Resort_Drone.png';
import CarouselImageCard from './CarouselImageCard';

function PhotoGrid() {
  return (
    <section>
      <section className='photo-grid-section d-xl-flex flex-xl-row flex-xl-wrap'>
        <ImageCard image={img_Elsa_Anna_1} imageElementClassName="photo-grid-item" />
        <ImageCard image={img_Star_Wars_0} imageElementClassName="photo-grid-item" />
        <ImageCard image={img_Home_Front} imageElementClassName="photo-grid-item" />
        <ImageCard image={img_Game_Room} imageElementClassName="photo-grid-item" />
        <ImageCard image={img_Pool_1} imageElementClassName="photo-grid-item" />
        <ImageCard image={img_Dining} imageElementClassName="photo-grid-item" />
        <ImageCard image={img_Lounge} imageElementClassName="photo-grid-item" />
        <ImageCard image={img_Resort} imageElementClassName="photo-grid-item" />
      </section>
      <section className='photo-carousel-section'>
        <div id="carouselImages" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="5" aria-label="Slide 5"></button>
            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="4" aria-label="Slide 6"></button>
            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="6" aria-label="Slide 7"></button>
            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="7" aria-label="Slide 8"></button>
          </div>
          <div class="carousel-inner">
            <CarouselImageCard image={img_Elsa_Anna_1} className="carousel-item active" />
            <CarouselImageCard image={img_Star_Wars_0} className="carousel-item" />
            <CarouselImageCard image={img_Home_Front} className="carousel-item" />
            <CarouselImageCard image={img_Game_Room} className="carousel-item" />
            <CarouselImageCard image={img_Pool_1} className="carousel-item" />
            <CarouselImageCard image={img_Dining} className="carousel-item" />
            <CarouselImageCard image={img_Lounge} className="carousel-item" />
            <CarouselImageCard image={img_Resort} className="carousel-item" />
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselImages" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselImages" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </section>
  );
}

export default PhotoGrid;
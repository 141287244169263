import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './js/reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VacationRentalWebApp from './js/VacationRentalWebApp';
import Success from './js/Success';
import Canceled from './js/Canceled';
import './css/index.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/success.html" element={<Success />}></Route>
                <Route path="/canceled.html" element={<Canceled />}></Route>
                <Route path="/" element={<VacationRentalWebApp />}></Route>
            </Routes>
        </Router>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
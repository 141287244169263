import '../css/Home.css';
import 'bootstrap';
import React from 'react';
import Availability from './Availability';
import PhotoGrid from './PhotoGrid';
import AboutUs from './AboutUs';
import Amenities from './Amenities';
import RoomsAndBeds from './RoomsAndBeds'

function Home() {
  return (
    <div className='container-fluid'>
      {/* <div className='home-anim-hero-rings-container w-screen z-1 pointer-events-none left-0'>
        <div className='home-anim-hero-rings absolute pin-hc'>
          <div className='home-anim-hero-ring pin-c absolute rounded-full ring ring-5'></div>
          <div className='home-anim-hero-ring pin-c absolute rounded-full ring ring-4'></div>
          <div className='home-anim-hero-ring pin-c absolute rounded-full ring ring-3'></div>
          <div className='home-anim-hero-ring pin-c absolute rounded-full ring ring-2'></div>
          <div className='home-anim-hero-ring pin-c absolute rounded-full ring ring-1'></div>
        </div>
      </div> */}
      <section className='d-flex flex-wrap flex-row justify-content-center'>
        <section className='main-section'>
          <h1>Vacation home: Kissimmee - Orlando, FL!</h1>
          <PhotoGrid />
          <hr></hr>
          <AboutUs />
          <hr></hr>
          <Amenities></Amenities>
          <hr></hr>
          <RoomsAndBeds></RoomsAndBeds>
          {/*<Resort />
          <Reviews /> */}
          <footer><i><b>Copyright, all rights reserved - PDKT Properties LLC</b></i></footer>
        </section>
        <section className='checkout-section'>
          <Availability />
        </section>
      </section>
    </div>
  );
}

export default Home;
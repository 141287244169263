import '../css/VacationRentalWebApp.css';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from './Nav';
import React from 'react';
import Home from './Home';

class VacationRentalWebApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 100 };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    return (
      <div className="container-fluid p-0" >
        <Nav />
        <Home />
      </div>
    );
  }
};

export default VacationRentalWebApp;

import '../css/AboutUs.css';
import React from 'react';

function AboutUs() {
  return (
    <section className="container-fluid" id="aboutUs">
      <h3 id="aboutUsHeading">About this rental</h3>
      <div>
        <ul className="list-unstyled list-inline">
          <li className="list-inline-item list-item-separator no-mobile">
            <div className="h5">House</div>
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">3317 sq. ft</li>
            </ul>
          </li>
          <li className="list-inline-item list-item-separator">
            <div className="h5">6 bedrooms</div>
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">9 beds</li>
              <li className="list-inline-item">Sleeps 15</li>
            </ul>
          </li>
          <li className="list-inline-item list-item-separator list-item-separator-no-mobile">
            <div className="h5">6.5 bathrooms</div>
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">6 full baths</li>
            </ul>
          </li>
          <li className="list-inline-item no-mobile">
            <div className="h5">Family spaces</div>
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">Kitchen</li>
              <li className="list-inline-item">Living Room</li>
              <li className="list-inline-item">Deck/Patio</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className='about-description'>
        <p>This home has full WATERPARK &amp; RESORT ACCESS!</p>

        <p>
          CLEANING NOTE: Our professional cleaning staff has developed new practices that achieve the highest standards of cleanliness and sanitization as recommended by the CDC and the industry standards set by the VRMA (Vacation Rental Management Association) to prevent the spread of Corona Virus (COVID-19). For a complete list of our protocols, please contact us.
        </p>
        <p>
          This is not a cookie cutter corporate Encore investor home. This is our family Disney vacation home for you to enjoy. Located on the same section as the Clubhouse & Waterpark all within walking distance, this house is a short stroll to all the amenities, which you will have FULL ACCESS to, as we are full members of the Encore Club. We also have a ping pong and foosball table to the game room at our place, along with a basketball, tennis rackets/balls and a volleyball to use on the Encore Resort Courts - no need to wait for the renal counter or use their equipment, use ours instead. All of you can also enjoy some board games, DVDs and few video games.
        </p>

        <div className="collapse" id="additionaldescription">
          <p>
            This is a luxury 6 bed 6.5 bath home in the exclusive Encore Club at Reunion. Every bedroom has it's own private bath! Located about 10 minutes from Disney property. Enjoy all the luxury of a 5 star hotel with the space and convenience of private luxury pool home. What makes our home special is the attention to detail. While all the homes at Encore provide luxury accommodations and fully equipped kitchens, there are a few extra things such as a pancake griddle, mixer, baking pans, extra bowls, acrylic dishes for outdoor dining and character dishes for the kids available at our place. We also added some beach equipment, which includes Tommy Bahama beach chairs, a beach umbrella and beach towels to make it easy for you to take a day trip to one of the beautiful beaches that are just a little over an hour away.
          </p>

          <p>
            Our home features a second floor lounge with a 55” TV, and a PlayStation 4 video game console with a large seating area. This house is designed with your family in mind with several spacious family areas in place! This was a model house and has a huge kitchen with an island, two dishwashers (and plenty of dishes), with counter seating for 6 and dining room seating for 8, plus a high chair and booster seat. There's also a high end outdoor dining with cushioned seating for 8. We've got a few extras in the kitchen, such as a Keurig coffee maker, a Magic Bullet smoother maker, and a big crockpot. Along with all of the standard Encore level serving and cooking utensils we've also upgraded the knife block and the replaced the aluminum cookware with stainless steel and heavy duty non-stick pans. If you are traveling with a little one, we’ve got you covered with a high chair, children’s dishes, a fold-up stroller and a portable pack and play. (Full size crib rentals are available.)
          </p>

          <p>
            As an extra bonus we have added two themed rooms to the house. After a family vote, the theme room winners were Frozen and Star Wars. The custom painted Frozen room has it’s own attached bath and features two double beds and a crystal chandelier. The Star Wars room also has it’s own attached bath and has a twin over double bunk with an additional pull out twin trundle bed. The rest of the accommodations include 4 King suites, all with en-suite bathrooms, Hunter ceiling fans and ample closet space. Two of these suites are designated masters with walk-in closets, double vanities and separate water closets. The kitchen and bathrooms all feature upgraded cabinetry, glass tile backsplash and upgraded granite. In addition we added stainless steel appliances, hardwood stairs and 18x18 tile throughout the downstairs common areas.
          </p>

          <p>
            Our house was built on a premium corner lot with and we chose the bigger 17x21 pool with a hot tub so that the whole family can enjoy the pool. We are in the Encore Phase 1 West section, which means we are on the same side of the road as all of the Encore amenities. Our house is walking distance to the Clubhouse and Waterpark and all they have to offer – slides, kid’s waterpark, resort pool, game room, gym, restaurant, bar and grill, check-in and concierge service. We are full Encore Club members, so that means that our guests have full access to all that Encore has to offer.
          </p>
          If you have any questions about our property, please don't hesitate to call.

          <p>
            What you can expect if you book "Our EZ Vacation Place" at Encore Club:
          </p>
          FABULOUS AMENITIES:

          <ul>
            <li>A 10-acre Aquapark that has something for everyone in you party, including the 4 different waterslides, the Surfin’ Safari Splash Zone and the resort style pool.</li>

            <li>The Encore Clubhouse where you will find the fitness center, the Grab-N-Go Market and the kid’s Hang Ten Hideaway.</li>

            <li>Finns Restaurant with delicious full-service or quick-service meals</li>

            <li>The Shark Lounge where you can meet for drinks and appetizers while you relax on the beautiful balcony overlooking the pool.</li>
          </ul>
          FABULOUS LOCATON:
          <ul>

            <li>Just 6 miles to Disney World and 15-20 miles to Sea World and Universal.</li>

            <li>Just 7 miles to Orlando Premium Outlets Vineland</li>

            <li>Shopping, groceries and restaurants all within a few miles!</li>
          </ul>

          HIGH END RESORT SETTING:
          <ul>
            <li>Professionally maintained private pool with hot tub (heat available at $40/day)</li>

            <li>Free Wi-Fi and cable TV (with 8 TV’s in the house)</li>

            <li>Fully equipped kitchen (and ours is above the Encore standard package)</li>

            <li>Resort style high quality linens including pool towels</li>

            <li>24/7 Maintenance</li>

            <li>Local expert management company on site and on call</li>
          </ul>
          CLEANING NOTE: Our professional cleaning staff has developed new practices that achieve the highest standards of cleanliness and sanitization as recommended by the CDC and the industry standards set by the VRMA (Vacation Rental Management Association) to prevent the spread of Corona Virus (COVID-19). We have strict guidelines and best practices in place and we have added additional cleanliness-specific guidelines such as treating all high-touch surfaces(including door knobs, lock boxes or electronics lock panels, stair railings, telephones, light switches, remote controls, arms of chairs, refrigerator door handles, sliding door handles, toilets, faucets and knobs, clothes hangers, touch screens etc.) with EPA compliant disinfectants. For a complete list of our protocols, please contact us.

        </div>
        <a data-bs-toggle="collapse" data-bs-target="#additionaldescription" href="#additionaldescription" aria-expanded="false" aria-controls="additionaldescription">More...</a>
      </div>
    </section>
  );
}

export default AboutUs;
import 'bootstrap';
import React from 'react';
import ImageCard from './ImageCard';

class CarouselImageCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={this.props.className}>
        <ImageCard image={this.props.image} imageElementClassName="carousel-card" />
      </div>
    );
  }
}

export default CarouselImageCard;
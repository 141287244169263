import '../css/Nav.css';
import 'bootstrap';
import React from 'react';

function Nav() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light nav-background sticky-top" id="vacation-rental-nav">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">EZ Vacation Rentals: Orlando</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarToggle">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-nav-scroll navScroll">
            <li className="nav-item">
              <a className="nav-link" href="#aboutUsHeading">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#amenitiesHeading">Amenities</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#roomsHeading">Rooms</a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="#resortHeading">Resort</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#reviewsHeading">Reviews</a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
import '../css/RoomsAndBeds.css';
import 'bootstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faShower } from '@fortawesome/free-solid-svg-icons';
import ImageCard from './ImageCard';
import img_Bedroom_1 from '../media/home/Bedroom_1.png';
import img_Bedroom_2 from '../media/home/Bedroom_2.png';
import img_Bedroom_3 from '../media/home/Bedroom_3.png';
import img_Bedroom_4 from '../media/home/Bedroom_3.png';
import img_Elsa_Anna_1 from '../media/home/Elsa_Anna_1.png';
import img_Star_Wars_0 from "../media/home/Star_Wars_0.png";

function RoomsAndBeds() {
  return (
    <div className="container-fluid" id="roomsAndBeds">
      <h3 id="roomsHeading">Rooms and Beds</h3>
      <div class="roomsContainer d-flex flex-wrap flex-row">
        <div className="room">
          <div className="roomTypeContainer">
            <span className="roomType">King Bed</span>
            <FontAwesomeIcon className="roomIcon" icon={faBed}></FontAwesomeIcon>
            <FontAwesomeIcon className="roomIcon" icon={faShower}></FontAwesomeIcon>
          </div>
          <div>
            <ImageCard image={img_Bedroom_1} />
          </div>
        </div>
        <div className="room">
          <div className="roomTypeContainer">
            <span className="roomType">King Bed</span>
            <FontAwesomeIcon className="roomIcon" icon={faBed}></FontAwesomeIcon>
            <FontAwesomeIcon className="roomIcon" icon={faShower}></FontAwesomeIcon>
          </div>
          <div>
            <ImageCard image={img_Bedroom_2} />
          </div>
        </div>
        <div className="room">
          <div className="roomTypeContainer">
            <span className="roomType">King Bed</span>
            <FontAwesomeIcon className="roomIcon" icon={faBed}></FontAwesomeIcon>
            <FontAwesomeIcon className="roomIcon" icon={faShower}></FontAwesomeIcon>
          </div>
          <div>
            <ImageCard image={img_Bedroom_3} />
          </div>
        </div>
        <div className="room">
          <div className="roomTypeContainer">
            <span className="roomType">King Bed</span>
            <FontAwesomeIcon className="roomIcon" icon={faBed}></FontAwesomeIcon>
            <FontAwesomeIcon className="roomIcon" icon={faShower}></FontAwesomeIcon>
          </div>
          <div>
            <ImageCard image={img_Bedroom_4} />
          </div>
        </div>
        <div className="room">
          <div className="roomTypeContainer">
            <span className="roomType">Double x 2</span>
            <FontAwesomeIcon className="roomIcon" icon={faBed}></FontAwesomeIcon>x2
            <FontAwesomeIcon className="roomIcon" icon={faShower}></FontAwesomeIcon>
          </div>
          <div>
            <ImageCard image={img_Elsa_Anna_1} />
          </div>
        </div>
        <div className="room">
          <div className="roomTypeContainer">
            <span className="roomType">Bunk</span>
            <FontAwesomeIcon className="roomIcon" icon={faBed}></FontAwesomeIcon>
            <FontAwesomeIcon className="roomIcon" icon={faShower}></FontAwesomeIcon>
          </div>
          <div>
            <ImageCard image={img_Star_Wars_0} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoomsAndBeds;
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Nav from './Nav';

function Success() {
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const orderId = searchParams.get("orderId");
  const [session, setsession] = useState(0);
  const [customerName, setcustomerName] = useState(0);
  const [customerEmail, setcustomerEmail] = useState(0);
  const [fromDate, setfromDate] = useState(0);
  const [toDate, settoDate] = useState(0);
  const [guests, setguests] = useState(0);
  const [bookingAmount, setbookingAmount] = useState(0);
  const [bookingSubTotalAmount, setbookingSubTotalAmount] = useState(0);
  const [taxAmount, settaxAmount] = useState(0);

  useEffect(() => {
    // axios.get('http://localhost:5000/vacation-rental-project/us-central1/api/checkout-session?sessionId=' + sessionId + '&orderId=' + orderId) // LOCAL
    // PROD
    axios.get('https://us-central1-vacation-rental-project.cloudfunctions.net/api/checkout-session?sessionId=' + sessionId + '&orderId=' + orderId)
      .then((res) => {
        setsession(res);
        console.log(session);
        setcustomerName(res.data.customerData.name);
        console.log("customer name:" + customerName);
        setcustomerEmail(res.data.customer_details.email);
        setbookingAmount(res.data.amount_total);
        setbookingSubTotalAmount(res.data.amount_subtotal);
        settaxAmount(res.data.total_details.amount_tax);
        setfromDate(res.data.metadata.fromDate);
        settoDate(res.data.metadata.toDate);
        setguests(res.data.metadata.guests);
      })
  }, []);

  return (
    <div className='container-fluid p-0'>
      <Nav></Nav>
      <div className='p-2 form-group'>
        <h1>{customerName}, Your payment succeeded. Thank you for booking with us!</h1>
        {/* <div>Full Response: <pre>{JSON.stringify(this.state.session, null, 2)}</pre></div> */}
        <div className='form-control'>Check-in: <b>{fromDate}</b> 10 AM EST</div>
        <div className='form-control'>Check-out: <b>{toDate}</b> 4 PM EST</div>
        <div className='form-control'>Guests: {guests}</div>
        <div className='form-control'>Email: {customerEmail}</div>
        <div className='form-control'>Booking Sub-total: {parseInt(bookingSubTotalAmount) / 100}</div>
        <div className='form-control'>Taxes: {parseInt(taxAmount) / 100}</div>
        <div className='form-control'>Booking Total: <b>US$ {parseInt(bookingAmount) / 100}</b></div>
        <div>We will send you a detailed E-mail once we receive your payment from Stripe. We will also send a booking information E-mail with your entry code and access instructions two-weeks prior to check-in! For any issues, please contact <a href='mailto:host@ezvacationrental.com'>host@ezvacationrental.com</a>.</div>
        <footer><i><b>Copyright, all rights reserved - PDKT Properties LLC</b></i></footer>
      </div>
    </div>
  );
}

export default Success;
